<template>
  <div class="permission-container flex">
    <!-- 侧边栏 -->
    <el-tabs
      v-if="getGradeListData && getGradeListData.length > 0"
      :tab-position="tabPosition"
      v-model="params.gradeId"
      @tab-click="toGetCourseAuthList(params)"
    >
      <el-tab-pane
        v-for="grade in getGradeListData"
        :key="grade.id"
        :name="grade.id + ''"
      >
        <div
          slot="label"
          class=""
          :ref="'tabPane' + grade.id"
          @mouseenter="showToolTip('tabPane' + grade.id, grade.cnName)"
          @mouseleave="hideToolTip"
        >
          {{ grade.cnName }}
        </div>
      </el-tab-pane>
    </el-tabs>
    <!-- 右侧 -->
    <div
      v-if="courseAuthSubjectList && courseAuthSubjectList.length > 0"
      style="padding-left: 25px; width: 100%"
    >
      <div class="course-count-title">
        {{ $t("el.courseList.all") }} {{ currentCourseAuthSubjectTotal }}
        {{ $t("el.courseList.totalcourses") }}
      </div>
      <div
        :id="'checkbox-group-content-' + type"
        class="checkbox-group-content"
      >
        <div
          v-for="(item, index) in courseAuthSubjectList"
          :key="item.subjectId"
        >
          <div class="course-type-title">
            {{ item.subjectName }}
            <el-checkbox
              v-if="!hideCheckChose"
              style="margin-left: 20px"
              :indeterminate="item.isIndeterminate"
              v-model="item.checkAll"
              @change="
                (...defaultArgs) =>
                  handleCheckAllChange(index, item, ...defaultArgs)
              "
              >{{ $t("el.common.SelectAll") }}</el-checkbox
            >
          </div>
          <div class="flex">
            <el-checkbox-group
              style="margin-left: 10px"
              class="flex flex-wrap course-group"
              :class="hideCheckChose ? 'hide-check' : ''"
              v-model="item.checkedCourseList"
              @change="
                (...defaultArgs) =>
                  handleCheckedCitiesChange(index, item, ...defaultArgs)
              "
            >
              <!-- 1、hideCheckChose 为 true 默认 或 是添加但已选时 隐藏选中check hide-check 2、当是添加但已选时 disable 禁选 -->
              <el-checkbox
                v-for="courseItem in item.courseAuthInfoList"
                :key="courseItem._uniqueId"
                :label="courseItem._uniqueId"
                style="margin-bottom: 10px"
                :class="[
                  hideCheckChose || (!hideCheckChose && courseItem.initChose)
                    ? 'hide-check'
                    : '',
                  isAddMode && courseItem.initChose ? 'unchose-border' : '',
                ]"
                :disabled="!hideCheckChose && courseItem.initChose"
              >
                <div
                  class="course-content"
                  :style="
                    hideCheckChose || (!hideCheckChose && courseItem.initChose)
                      ? 'width: 186px'
                      : ''
                  "
                >
                  <img
                    v-if="!hideCheckChose && courseItem.initChose"
                    src="@/assets/images/icon/icon-already.png"
                    class="course-content-img"
                  />
                  <div
                    class="course-content-title single-line"
                    :ref="'courseName' + courseItem._uniqueId"
                    @mouseenter="
                      showToolTip(
                        'courseName' + courseItem._uniqueId,
                        courseItem.courseName,
                        '16px'
                      )
                    "
                    @mouseleave="hideToolTip"
                  >
                    {{ courseItem.courseName }}
                  </div>
                  <div
                    class="course-content-grade single-line"
                    :ref="'originTenantName' + courseItem._uniqueId"
                    @mouseenter="
                      showToolTip(
                        'originTenantName' + courseItem._uniqueId,
                        courseItem.originTenantName,
                        '12px'
                      )
                    "
                    @mouseleave="hideToolTip"
                  >
                    {{ courseItem.originTenantName }}
                  </div>
                  <div
                    class="course-content-grade single-line"
                    :ref="'gradeName' + courseItem._uniqueId"
                    @mouseenter="
                      showToolTip(
                        'gradeName' + courseItem._uniqueId,
                        courseItem.gradeName,
                        '12px'
                      )
                    "
                    @mouseleave="hideToolTip"
                  >
                    {{ courseItem.gradeName }}
                  </div>
                  <div
                    v-if="courseItem.limitSchoolNames"
                    class="course-content-school single-line"
                    :ref="'limitSchoolNames' + courseItem._uniqueId"
                    @mouseenter="
                      showToolTip(
                        'limitSchoolNames' + courseItem._uniqueId,
                        courseItem.limitSchoolNames,
                        '12px'
                      )
                    "
                    @mouseleave="hideToolTip"
                  >
                    {{ courseItem.limitSchoolNames }}
                  </div>
                  <div
                    v-if="type === 'add'"
                    class="course-content-time single-line"
                    :style="
                      type === 'add' && courseItem.isTry
                        ? 'width: calc(100% - 30px)'
                        : ''
                    "
                    :ref="'expiresDate' + courseItem._uniqueId"
                    @mouseenter="
                      showToolTip(
                        'expiresDate' + courseItem._uniqueId,
                        MaximumAuthorized +
                          (courseItem.expiresDate
                            ? courseItem.expiresDate
                            : courseItem.expiresDateDescribe),
                        '12px'
                      )
                    "
                    @mouseleave="hideToolTip"
                  >
                    {{ MaximumAuthorized }}

                    {{
                      courseItem.expiresDate
                        ? courseItem.expiresDate
                        : courseItem.expiresDateDescribe
                    }}
                  </div>
                  <div
                    v-else
                    class="course-content-time single-line"
                    :class="
                      courseItem.isExpiringSoon ? 'course-content-time-red' : ''
                    "
                    :ref="'expiresDate' + courseItem._uniqueId"
                    @mouseenter="
                      showToolTip(
                        'expiresDate' + courseItem._uniqueId,
                        courseItem.expiresDate
                          ? courseItem.expiresDate + $t('el.common.expire')
                          : courseItem.expiresDateDescribe,
                        '12px'
                      )
                    "
                    @mouseleave="hideToolTip"
                  >
                    {{
                      courseItem.expiresDate
                        ? courseItem.expiresDate + $t("el.common.expire")
                        : courseItem.expiresDateDescribe
                    }}
                  </div>
                  <div v-if="courseItem.availableTimePeriodVO?.length">
                    <!-- <el-popover
                        class="item"
                        effect="light"
                        trigger="hover"
                        placement="bottom-start"
                      >
                        <div>
                          <p
                            v-for="i in courseItem.availableTimePeriodVO"
                            :key="i.name"
                          >
                            {{
                              (i.name.length > 25
                                ? i.name.slice(0, 25 - 3) + "..."
                                : i.name) +
                              "  (" +
                              secondsToTime(i.startTime) +
                              "-" +
                              secondsToTime(i.endTime) +
                              ")"
                            }}
                          </p>
                        </div>
                        <el-button
                          slot="reference"
                          type="warning"
                          round
                          plain
                          size="mini"
                          style="
                            padding: 6px 14px;
                            margin-top: 3px;
                            line-height: 12px;
                            font-size: 12px;
                            color: #ffa700;
                            background: #ffa7001a;
                            border-color: transparent;
                          "
                          onfocus="this.blur()"
                          >查看时间段</el-button
                        >
                      </el-popover> -->
                    <div
                      class="course-content-school"
                      :ref="'periodBtn' + courseItem._uniqueId"
                      @mouseenter="
                        showToolTip(
                          'periodBtn' + courseItem._uniqueId,
                          courseItem.availableTimePeriodVO,
                          '12px',
                          true
                        )
                      "
                      @mouseleave="hideToolTip"
                      style="
                        padding: 6px 14px;
                        margin-top: 6px;
                        line-height: 12px;
                        font-size: 12px;
                        color: #ffa700;
                        background: #ffa7001a;
                        border-color: transparent;
                        border-radius: 12px;
                        width: 60px;
                        height: 12px;
                        text-align: center;
                      "
                    >
                      查看时间段
                    </div>
                    <div
                      v-if="courseItem.isTry"
                      class="course-content-test"
                      :style="type === 'add' ? 'bottom: 14px;' : ''"
                      style="bottom: unset; top: 118px"
                    >
                      {{ $t("el.common.onTrial") }}
                    </div>
                  </div>
                </div>
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
      </div>
    </div>
    <!-- 无数据 -->
    <div
      v-else
      style="
        padding-left: 25px;
        padding-top: 160px;
        width: 100%;
        min-height: 500px;
        height: 100%;
        text-align: center;
        box-sizing: border-box;
      "
    >
      <el-image
        style="
          margin-left: auto;
          margin-right: auto;
          width: 280px;
          height: 160px;
        "
        :src="emptyCourse"
        fit="fit"
      ></el-image>
      <div style="margin-left: auto; margin-right: auto; color: #999999">
        {{ $t("el.common.NoCourse") }}
      </div>
    </div>
    <!-- 提交按钮 -->
    <div class="permission-select flex flex-m" style="max-width: 580px">
      <!-- <el-cascader
        style="width:200px;"
        v-model="schoolCode"
        placeholder="请选择学校"
        :options="getSchoolTreeList"
        clearable
        :show-all-levels="false"
        :props="schoolPropsObj"
        @change="toGetCourseAuthList(params)"
      >
      </el-cascader> -->
      <el-select
        v-if="!isAddMode"
        v-model="isTrySelect"
        :popper-append-to-body="false"
        :placeholder="$t('el.common.pleaseSelect')"
        size="small"
        style="width: 100px; margin-right: 10px"
        @change="toGetCourseAuthList(params)"
      >
        <el-option
          v-for="item in courseTypeList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <span class="color3" style="font-size: 14px">{{
        $t("el.planCourse.expirationDate") + $t("el.common.colon")
      }}</span>
      <el-date-picker
        v-model="expirationDate"
        style="width: 180px"
        type="daterange"
        range-separator="-"
        value-format="yyyy-MM-dd"
        format="yyyy/MM/dd"
        :start-placeholder="$t('el.common.startTime')"
        :end-placeholder="$t('el.common.endTime')"
        @change="changeDate"
        size="small"
      >
      </el-date-picker>
      <el-input
        v-model="params.courseName"
        style="margin-left: 10px; width: 200px"
        :style="$i18n.locale === 'en_US' ? 'width: 200px' : ''"
        :placeholder="$t('el.courseList.enterCourseName')"
        size="small"
        @keyup.enter.native="toGetCourseAuthList(params)"
      >
        <el-button
          slot="append"
          icon="el-icon-search"
          @click="toGetCourseAuthList(params)"
        ></el-button>
      </el-input>
    </div>
    <el-popover
      v-if="showPopover"
      :placement="isPeriodTooltip ? 'bottom-start' : 'top'"
      title=""
      :popper-class="isPeriodTooltip ? '' : 'tool-popover'"
      :append-to-body="false"
      width="fit-content"
      trigger="manual"
      v-model="visible"
      :effect="isPeriodTooltip ? 'light' : ''"
    >
      <template v-if="isPeriodTooltip">
        <p v-for="i in popoverContent" :key="i.name">
          {{
            (i.name.length > 25 ? i.name.slice(0, 25 - 3) + "..." : i.name) +
            "  (" +
            secondsToTime(i.startTime) +
            "-" +
            secondsToTime(i.endTime) +
            ")"
          }}
        </p>
      </template>
      <div v-else class="content-text">
        {{ popoverContent }}
      </div>

      <el-button
        slot="reference"
        class="popover-btn"
        :style="'position: absolute; height: 0; width: 0;' + btnPosition"
        @click="visible = !visible"
      ></el-button>
    </el-popover>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import { handparams } from "@/utils/index.js";
// import tooltipOver from "@/components/biz-components/tooltipOver/index.vue";
import { parse } from "date-fns";
import { courseAuthId } from "@/utils/auth";
import { timeToSeconds, secondsToTime } from "@/utils";
export default {
  name: "CoursePermission",
  components: {
    // tooltipOver,
  },
  data() {
    return {
      secondsToTime,
      // schoolPropsObj: {
      //   value: "schoolCode",
      //   label: "schoolName",
      //   children: "children",
      //   multiple: false,
      // },
      // schoolCode: "",
      isTrySelect: "", // 课程类型选择值 字段
      emptyCourse: require("@/assets/images/pic_empty_course@2x.png"),
      expirationDate: [], // 到期时间
      visible: false,
      showPopover: false,
      hideCheckChose: false,
      isEditMode: false,
      isAddMode: false,
      popoverContent: "",
      isPeriodTooltip: false,
      btnPosition: "", // 当前位置
      params: {
        courseName: "",
        gradeId: "-1",
        // schoolCode: "",
        staffCode: "",
      },
      value: "",
      input: "",
      tabPosition: "left",
      courseAuthSubjectList: [],
      allAuthCourseIds: [],
      currentAuthCourseIds: [],
      noAuthCourseId: [],
      differenceArray: [],
    };
  },
  props: {
    // 默认传入的选中值
    authCourseIds: {
      type: Array,
      default: () => [],
    },
    staffCode: {
      type: [String, Number],
      default: "",
    },
    getGradeListData: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: "default",
    },
    isTry: {
      type: Boolean,
      default: false,
    },
  },
  created() {},
  async mounted() {
    this.hideCheckChose = this.type === "default" || this.type === "";
    this.isEditMode = this.type === "edit";
    this.isAddMode = this.type === "add";
    // this.getSchoolTree();
    let data = {};
    ~~data.gradeId === -1 && (data.gradeId = "");
    data.userId = this.staffCode;
    if (this.isTry) {
      data.isTry = 1;
    } else if (this.isAddMode) {
      data.isTry = 0;
    } else {
      data.isTry = this.isTrySelect;
    }
    this.$emit("setAllLoading", true);
    if (!this.isAddMode) {
      await this.getCourseAuthList(handparams(data)); // 课程列表接口
    } else {
      await this.getAddCourseAuthList(handparams(data));
    }
    this.allAuthCourseIds = this.authCourseIds;

    this.$emit("setAllLoading", false);
    this.courseAuthSubjectList = this.getCourseAuthSubjectList(
      // 第一次直接回显
      this.allAuthCourseIds,
      this.authCourseIds,
      this.type
    );
  },
  computed: {
    ...mapState({
      courseAuthSubjectTotal: (state) =>
        state.userManage.courseAuthSubjectTotal,
      addCourseAuthSubjectTotal: (state) =>
        state.userManage.addCourseAuthSubjectTotal,
    }),
    currentCourseAuthSubjectTotal() {
      return this.isAddMode
        ? this.addCourseAuthSubjectTotal
        : this.courseAuthSubjectTotal;
    },
    ...mapGetters(["getSchoolTreeList", "getCourseAuthSubjectList"]),
    MaximumAuthorized() {
      return (
        this.$t("el.courseList.MaximumAuthorized") + this.$t("el.common.colon")
      );
    },
    courseTypeList() {
      return [
        {
          value: "",
          label: this.$t("el.courseList.allCourse"),
        },
        {
          value: 0,
          label: this.$t("el.courseList.formalCourses"),
        },
        {
          value: 1,
          label: this.$t("el.courseList.trialCourses"),
        },
      ];
    },
  },
  watch: {
    // authCourseIds: {
    //   handler(newVal, oldVal) {
    //     this.$nextTick(() => {
    //       this.courseAuthSubjectList = this.getCourseAuthSubjectList(newVal);
    //     });
    //   },
    //   immediate: true,
    //   deep: true,
    // },
  },
  methods: {
    ...mapActions([
      "getSchoolTree",
      "getCourseAuthList",
      "getAddCourseAuthList",
    ]),
    showToolTip(refName, content, contentFontSize, isPeriodTooltip) {
      this.showPopover = true;
      this.popoverContent = content;
      this.isPeriodTooltip = Boolean(isPeriodTooltip);
      let parentWidth = this.$refs[refName][0].offsetWidth;
      let left = this.$refs[refName][0].offsetParent.offsetLeft;
      let childLeft = this.$refs[refName][0].offsetLeft;
      let top = this.$refs[refName][0].offsetParent.offsetTop;
      let childTop = this.$refs[refName][0].offsetTop;
      // let contentWidth = this.$refs[refName].offsetWidth;
      let scrollTop = document.getElementById(
        "checkbox-group-content-" + this.type
      ).scrollTop;
      top = top - scrollTop + 5;
      this.btnPosition =
        "top:" +
        (top + childTop + (isPeriodTooltip ? 15 : 0)) +
        "px;" +
        "left:" +
        (left + childLeft + (isPeriodTooltip ? 45 : 90)) +
        "px;";
      this.$nextTick(() => {
        if (this.isPeriodTooltip) return (this.visible = true);
        /* currentWidth 为文本在页面中所占的宽度，创建标签，加入到页面，获取currentWidth ,最后在移除*/
        let TemporaryTag = document.createElement("span");
        TemporaryTag.innerText = this.popoverContent;
        TemporaryTag.style.fontSize = contentFontSize || "14px";
        TemporaryTag.className = "getTextWidth";
        document.querySelector("body").appendChild(TemporaryTag);
        let currentWidth = document.querySelector(".getTextWidth").offsetWidth;
        document.querySelector(".getTextWidth").remove();
        // if (this.overFlowNum) {
        //   contentWidth = currentWidth / this.overFlowNum;
        // } else {
        //   contentWidth = currentWidth / 2;
        // }
        let contentWidth = currentWidth;
        // 判断是否开启tooltip功能
        if (contentWidth > parentWidth) {
          // this.isShowTooltip = false;
          this.visible = true;
        } else {
          this.visible = false;
        }
      });
    },
    hideToolTip() {
      this.$nextTick(() => {
        this.visible = false;
        this.showPopover = false;
      });
    },

    changeDate(newVal) {
      if (newVal && newVal.length === 2) {
        this.params.expiresStartDate = newVal[0];
        this.params.expiresEndDate = newVal[1];
      } else {
        delete this.params.expiresStartDate;
        delete this.params.expiresEndDate;
      }
      this.toGetCourseAuthList(this.params);
    },

    // 全选点击
    handleCheckAllChange(index, item, val) {
      if (val) {
        item.checkAll = true;

        item.checkedCourseList = item.courseAuthInfoList.map((item) => {
          return item._uniqueId;
        });
        item.isIndeterminate = false;
      } else {
        item.checkedCourseList = item.courseAuthInfoList
          .filter((item) => {
            return item.initChose && item.courseId;
          })
          .map((item) => {
            return item._uniqueId;
          });
        // item.checkedCourseList = [];
        item.checkAll = false;
        if (item.checkedCourseList.length !== 0) {
          item.isIndeterminate = true;
        } else {
          item.isIndeterminate = false;
        }
      }
      // item.isIndeterminate = false;
      this.$set(this.courseAuthSubjectList, index, item);
      this.getAuthCourseIds();
      let maxItem = this.getMaxAuthorizeDate();
      let extraAuthCourseIds = this.getExtraAuthCourseIds();
      this.$emit(
        "get-max-authorize-date",
        maxItem,
        extraAuthCourseIds.length,
        this.isEditMode
      );
    },
    // checkoutbox 点击
    handleCheckedCitiesChange(index, item, value) {
      // 如果隐藏选择，不做操作 或 已选
      if (this.hideCheckChose) {
        return;
      }
      let checkedCount = item.checkedCourseList.length;
      item.checkAll = checkedCount === item.courseAuthInfoList.length;
      item.isIndeterminate =
        checkedCount > 0 && checkedCount < item.courseAuthInfoList.length;
      this.$set(this.courseAuthSubjectList, index, item);
      this.getAuthCourseIds();
      let minItem = this.getMaxAuthorizeDate();
      let extraAuthCourseIds = this.getExtraAuthCourseIds();
      this.$emit(
        "get-max-authorize-date",
        minItem,
        extraAuthCourseIds.length,
        this.isEditMode
      );
    },
    getExtraAuthCourseIds() {
      let courseIds = this.filterArray(
        this.currentAuthCourseIds,
        this.authCourseIds
      ); // 选中的课程中 去除初始选中的课程，id
      return courseIds;
    },
    // 获取当前最大日期
    getMaxAuthorizeDate() {
      let expiresDate;
      let uniqueIds = this.filterArray(
        this.currentAuthCourseIds,
        this.authCourseIds
      ); // 选中的课程中 去除初始选中的课程，id
      let courseList = [];

      this.courseAuthSubjectList.forEach((item) => {
        courseList = courseList.concat(item.courseAuthInfoList);
      }); // 所有的 course
      // 根据选中课程 id 获取 选中的item 集合
      let courseItemChoseList = courseList.filter(
        (item) => uniqueIds.indexOf(item._uniqueId) > -1
      );
      let item = courseItemChoseList.find((item) => {
        if (this.isEditMode) {
          // expiresDate = item && item.maxExpiresDate === "永久";
          return item && item.expiresType === 1;
        }
        // expiresDate = item && item.expiresDateDescribe;
        return item && item.expiresType === 1;
      }); // 判断是否存在不是永久
      if (!item) {
        expiresDate =
          (courseItemChoseList[0] &&
            courseItemChoseList[0].expiresDateDescribe) ||
          "";
        return courseItemChoseList.length > 0 ? courseItemChoseList[0] : {};
      } else {
        let minItem = {};
        let maxExpiresDateLong = 0;
        // 不是到期时间永久的话，比较到期时间
        courseItemChoseList.forEach((element) => {
          // 如果是编辑模式，取另一个字段
          if (this.isEditMode) {
            if (element.maxExpiresDate && element.expiresType === 1) {
              element.expiresDateLong = parse(
                element.maxExpiresDate,
                "yyyy/MM/dd",
                new Date()
              ).getTime();
            }
          } else {
            if (element.expiresDate && element.expiresType === 1) {
              element.expiresDateLong = parse(
                element.expiresDate,
                "yyyy/MM/dd",
                new Date()
              ).getTime();
            }
          }
          if (
            element.expiresType === 1 &&
            (element.expiresDateLong < maxExpiresDateLong ||
              maxExpiresDateLong === 0)
          ) {
            minItem = element;
            maxExpiresDateLong = element.expiresDateLong;
          }
        });
        return minItem;
      }
    },
    // 去除数组A中包含数组B中的元素
    filterArray(A, B) {
      let newArr = A.filter((itemA) => {
        return B.every((itemB) => {
          return itemA != itemB;
        });
      });
      return newArr;
    },
    // 获取课程权限列表
    toGetCourseAuthList(params) {
      this.$emit("setAllLoading", true);
      !params && (params = this.params);
      this.$nextTick(async () => {
        let data = {};
        params && (data = handparams(params));
        ~~data.gradeId === -1 && (data.gradeId = "");
        data.userId = this.staffCode;
        if (this.schoolCode && this.schoolCode.length === 1) {
          data.schoolCode = this.schoolCode[0];
        } else if (this.schoolCode && this.schoolCode.length === 3) {
          data.schoolCode = this.schoolCode[2];
        }
        if (this.isTry) {
          data.isTry = 1;
        } else if (this.isAddMode) {
          data.isTry = 0;
        } else {
          data.isTry = this.isTrySelect;
        }
        this.currentAuthCourseIds = this.getAuthCourseIds(); // 保存当前 选中的课程
        if (!this.isAddMode) {
          await this.getCourseAuthList(handparams(data)); // 课程列表接口
        } else {
          await this.getAddCourseAuthList(handparams(data));
        }
        // 获取权限check 整个列表回显
        this.courseAuthSubjectList = this.getCourseAuthSubjectList(
          this.currentAuthCourseIds,
          this.authCourseIds,
          this.type
        );

        this.$emit("setAllLoading", false);
      });
    },
    // 所有选中的课程id
    getAuthCourseIds() {
      let allCheckedCourseList = this.currentAuthCourseIds; // 所有选中的课程
      // let noAuthCourseId = []; // 所有课程
      this.courseAuthSubjectList.forEach((element) => {
        // 判断未勾选的
        const remove = element.courseAuthInfoList
          .filter((item) => !element.checkedCourseList.includes(item._uniqueId))
          .map((item) => courseAuthId(item));

        allCheckedCourseList = this.removeDuplicates(
          allCheckedCourseList
            .concat(element.checkedCourseList)
            .filter((item) => !remove.includes(item))
        );

        // element.courseAuthInfoList.forEach((ele) => {
        //   noAuthCourseId.push(ele._uniqueId);
        // });
      });
      // this.differenceArray = this.getArrDifference(
      //   // 保存当前人权限中的课程不属于当前tab页所有课程的课程
      //   this.allAuthCourseIds, // 初始勾选的对象
      //   noAuthCourseId
      // );
      // this.currentAuthCourseIds = this.removeDuplicates(
      //   allCheckedCourseList.concat(
      //     // 当前所有选中的课程，包含隐藏的
      //     this.differenceArray.map((item) => courseAuthId(item))
      //   )
      // );
      this.currentAuthCourseIds = this.removeDuplicates(allCheckedCourseList);
      // 当前选中的所有课程 和 总的课程取交集，再和 当前所有课程合并，去掉取消的课程
      // let arr3 = this.currentAuthCourseIds.filter((item) => {
      //   return this.allAuthCourseIds.includes(item);
      // });
      // let a = this.currentAuthCourseIds.concat(arr3);

      return this.removeDuplicates(this.currentAuthCourseIds);
    },
    // 获取第一个数组在第二个数组中不存在
    getArrDifference(arr1, arr2) {
      let a = [];
      arr1.forEach((element) => {
        if (arr2.indexOf(element) === -1) {
          a.push(element);
        }
      });
      return a;
    },
    removeDuplicates(arr) {
      return Array.from(new Set(arr));
    },
  },
};
</script>

<style lang="less" scoped>
.popover-btn {
  background-color: #131313;
  border: 0px solid #333333;
  display: inline-block;
  padding: 0 0;
}
.permission-container {
  position: relative;
  border: 1px solid #dcdddd;
  padding-top: 13px;
  border-radius: 4px;

  /deep/ .el-tabs__item {
    font-size: 14px !important;
  }

  /deep/ .el-tabs__active-bar {
    display: none;
    position: absolute;
    bottom: 0;
    left: auto;
    height: 2px;
    background-color: #6049ff !important;
    z-index: 1;
    -webkit-transition: -webkit-transform 0.3s
      cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
      -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    list-style: none;
  }
  .tab-content {
    padding-left: 25px;
  }
  .permission-select {
    position: absolute;
    top: 13px;
    right: 18px;
  }
  .course-count-title {
    width: fit-content;
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #999999;
    padding-top: 12px;
    padding-bottom: 14px;
  }
  .course-type-title {
    width: fit-content;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #131313;
    margin-top: 25px;
    margin-bottom: 19px;
  }
  .course-group {
    // width: 750px;
    /deep/ .el-checkbox {
      color: #606266;
      font-weight: 500;
      font-size: 14px;
      position: relative;
      cursor: pointer;
      border: 1px solid #f5f5f5;
      border-radius: 4px;
      padding: 13px 8px 11px 0;
      display: flex;
      flex-direction: row-reverse;
      white-space: nowrap;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      margin-right: 10px;
      vertical-align: top;
    }

    .unchose-border {
      border: 1px solid #f5f5f5 !important;
    }
    /deep/ .is-checked {
      border: 1px solid #5a53f5;
    }
    /deep/ .el-checkbox__input {
      border: 1px solid transparent !important;
    }
  }
  // 隐藏选中
  .hide-check {
    cursor: auto !important;
    .is-checked {
      border: 1px solid #f5f5f5 !important;
    }
    // /deep/ .el-checkbox__input.is-disabled + span.el-checkbox__label {
    //   color: #c0c4cc;
    //   cursor: auto !important;
    // }
    // /deep/ .el-checkbox {
    //   cursor: auto !important;
    // }
    /deep/ .el-checkbox__input {
      display: none;
    }
    // /deep/ .is-checked {
    //   border: 1px solid #f5f5f5 !important;
    // }
  }
  .course-content {
    width: 170px;
    box-sizing: border-box;
    &-img {
      position: absolute;
      width: 38px;
      height: 38px;
      top: 0;
      right: 0;
    }
    &-title {
      width: 100%;

      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
      margin-bottom: 7px;
    }
    &-grade {
      width: 100%;
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #999999;
      margin-bottom: 9px;
    }
    &-test {
      position: absolute;
      right: 8px;
      bottom: 8px;
      width: fit-content;
      height: fit-content;
      padding: 3px 12px;
      background: rgba(0, 118, 244, 0.1);
      border-radius: 20px;
      font-size: 12px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #0076f4;
    }
    &-school {
      width: 100%;
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #999999;
      margin-bottom: 6px;
    }
    &-time {
      width: 100%;
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #666666;
    }
    &-time-red {
      color: #cf6779;
    }
  }
  /deep/ .el-tabs--left,
  .el-tabs--right {
    overflow: inherit;
  }
  /deep/ .el-checkbox__input {
    white-space: nowrap;
    cursor: pointer;
    outline: none;
    display: inline-block;
    line-height: 1;
    position: relative;
    vertical-align: top;
    margin-top: 2px;
  }
  /deep/ .el-checkbox {
    color: #606266;
    font-weight: 500;
    font-size: 14px;
    position: relative;
    cursor: pointer;
    display: inline-block;
    white-space: nowrap;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-right: 30px;
    vertical-align: top;
  }
  /deep/ .el-tabs__nav {
    white-space: nowrap;
    position: relative;
    -webkit-transition: -webkit-transform 0.3s;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
    float: left;
    z-index: 2;
    background: #f4f5f8;
  }
  /deep/ .el-tabs--left .el-tabs__item.is-left {
    text-align: center !important;
    width: 120px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  /deep/ .el-tabs__item.is-active {
    color: #131313 !important;
    background: white !important;
  }
  /deep/ .el-tabs__item:hover {
    color: #5a53f5;
    cursor: pointer;
  }
  /deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #5a53f5;
    border-color: #5a53f5;
  }
  /deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #5a53f5;
    border-color: #5a53f5;
  }
}
</style>
<style lang="less">
.tool-popover {
  width: fit-content;
  // max-width: 200px;
  // min-width: 0;
  padding: 8px 10px;
  font-size: 14px;
  border-radius: 6px;

  background-color: rgba(50, 50, 50, 0.7) !important;
  color: #fff !important;
  .content-text {
    width: 200px;
  }
}
.permission-container {
  .tool-popover.el-popper[x-placement^="top"] .popper__arrow::after {
    bottom: 1px;
    margin-left: -6px;
    border-top-color: rgba(50, 50, 50, 0.7) !important;
    border-bottom-width: 0;
  }
}
.color3 {
  width: fit-content;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
}
</style>
