<template>
  <div class="t-c btn-content">
    <div class="flex flex-m m-auto">
      <span class="color3" style="font-size: 14px">{{
        $t("el.courseList.CourseExpirationTime") + $t("el.common.colon")
      }}</span>
      <!-- 最大时间不是永久时 -->
      <el-date-picker
        v-if="
          (currentExpirationDate !== maxAuthorizeDateItem.expiresDateDescribe &&
            currentExpirationDate !== maxAuthorizeDateItem.maxExpiresDate) ||
          !currentExpirationDate
        "
        v-model="currentExpirationDate"
        type="date"
        size="small"
        value-format="yyyy/MM/dd"
        :picker-options="pickerOptions"
        format="yyyy/MM/dd"
        :placeholder="$t('el.planCourse.SelectRange')"
      >
      </el-date-picker>
      <div class="flex flex-m" v-else>
        <span>{{ currentExpirationDate }}</span>
        <el-button
          @click="onCustomDate"
          style="margin-left: 16px"
          type="text"
          >{{ $t("el.common.Custom") }}</el-button
        >
      </div>
    </div>
    <slot name="appendContent"></slot>
    <div
      v-if="chosedAuthCourseIdsLength > 0"
      class="flex flex-c flex-m m-auto btn-content-date"
      style="margin: 10px auto"
    >
      <el-popover
        placement="top-start"
        width="200"
        popper-class="proper-question"
        trigger="hover"
        :content="$t('el.courseList.MaximumAuthorizedDate')"
      >
        <el-image
          style="width: 15px; height: 15px; margin-right: 4px"
          :src="iconQuestion"
          slot="reference"
        ></el-image>
      </el-popover>
      <span
        >{{ $t("el.courseList.MaximumAuthorized") + $t("el.common.colon") }}
        {{
          maxAuthorizeDateItem.isEditMode
            ? maxAuthorizeDateItem.maxExpiresDate
            : maxAuthorizeDateItem.expiresDate ||
              maxAuthorizeDateItem.expiresDateDescribe
        }}</span
      >
      <el-button
        @click="onHandleUseDate"
        style="margin-left: 16px"
        type="text"
        >{{ $t("el.common.Use") }}</el-button
      >
    </div>
    <div
      v-else
      class="flex flex-c flex-m m-auto btn-content-date"
      style="margin: 10px auto; height: 20px"
    ></div>
    <div class="flex form-btn m-auto">
      <el-button @click="closeDialogCourse" class="update" size="small">{{
        $t("el.common.cancel")
      }}</el-button>
      <el-button
        class="plus"
        type="primary"
        size="small"
        style="margin-left: 20px"
        @click="onHandleAddCourseSubmit"
        >{{ submitText }}</el-button
      >
    </div>
  </div>
</template>

<script>
import { parse, subDays } from "date-fns";
export default {
  name: "BtnAuthorizeDate",
  data() {
    return {
      iconQuestion: require("@/assets/images/icon/icon-questions-desc.png"),
      currentExpirationDate: "",
    };
  },
  props: {
    chosedAuthCourseIdsLength: {
      type: Number,
      default: 0,
    },
    expirationDate: {
      type: String,
      default: "",
    },
    maxAuthorizeDateItem: {
      type: Object,
      default: () => {},
    },
    submitText: {
      type: String,
      default: "",
    },
  },
  mounted() {
    // this.currentExpirationDate = this.expirationDate;
  },
  // 计算属性
  computed: {
    pickerOptions() {
      return {
        disabledDate: (time) => {
          let expiresDate = this.maxAuthorizeDateItem.isEditMode
            ? this.maxAuthorizeDateItem.maxExpiresDate
            : this.maxAuthorizeDateItem.expiresDate ||
              this.maxAuthorizeDateItem.expiresDateDescribe;
          if (expiresDate === "永久") {
            return time.getTime() < subDays(new Date(), 1);
          } else {
            return (
              time.getTime() < subDays(new Date(), 1) ||
              time.getTime() >
                parse(expiresDate, "yyyy/MM/dd", new Date()).getTime()
            );
          }
        },
      };
    },
  },
  watch: {
    currentExpirationDate(newVal, oldVal) {
      this.$emit("setExpirationDate", newVal);
    },
    expirationDate: {
      handler(newVal, oldVal) {
        console.log("watch====expirationDate=======", newVal);
        this.currentExpirationDate = newVal;
        // this.$emit("setExpirationDate", newVal);
      },
      immediate: true,
    },
  },
  methods: {
    onHandleUseDate() {
      this.$emit("onHandleUseDate");
    },

    closeDialogCourse() {
      this.$emit("closeDialogCourse");
    },
    onHandleAddCourseSubmit() {
      this.$emit("onHandleAddCourseSubmit", this.currentExpirationDate);
    },
    onCustomDate() {
      this.$emit("onCustomDate");
    },
  },
};
</script>

<style lang="less" scoped>
.btn-content {
  position: absolute;
  bottom: 0;
  left: 50%;
  height: 150px;
  transform: translateX(-50%);
  .m-auto {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
